.navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: white;
}

.nav-content {
  padding: 0 40px;
  width: 100%;
  display: flex;
}

.nav-items-left {
  display: flex;
  align-items: center;
  height: auto;
  flex-grow: 1;
}

.branding {
  display: flex;
  cursor: pointer;
}

.branding img {
  height: 28px;
  background-color: black;
}
.brand-text {
  font-size: 18px;
  color: gray;
  margin-bottom: 0 !important;
}

.nav-items-right .avatar{
  cursor: pointer;
}
