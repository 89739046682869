.container {
  /* background: #fff; */
  min-width: 400px !important;
  width: 100%;
  padding: 25px 30px;
  border-radius: 5px;
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15); */
}
.container .form .title {
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  position: relative;
}
.container .form .title:before {
  content: "";
  position: absolute;
  height: 4px;
  width: 33px;
  left: 0px;
  bottom: 3px;
  border-radius: 5px;
  background: linear-gradient(to right, #801ae6 0%, #a21ae6 100%);
}
.container .form .input-box {
  width: 100%;
  height: 45px;
  margin-top: 25px;
  position: relative;
}
.container .form .input-box input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 16px;
  font-weight: 400 !important;
  border: none;
  background: none;
  color: #ffffff;
}

.container .form .underline::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.3s ease;
}
.container .form .input-box input:focus ~ .underline::after,
.container .form .input-box input:valid ~ .underline::after {
  transform: scaleX(1);
  transform-origin: left;
}
.container .form .button {
  margin: 40px 0 20px 0;
}
.container .input-box input[type="submit"] {
  background: linear-gradient(to right, #801ae6 0%, #a21ae6 100%);
  font-size: 17px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.container .input-box input[type="submit"]:hover {
  letter-spacing: 1px;
  background: linear-gradient(to right, #801ae6 0%, #a21ae6 100%);
}
.container .option {
  font-size: 14px;
  text-align: center;
}
.container .facebook a,
.container .twitter a {
  display: block;
  height: 45px;
  width: 100%;
  font-size: 15px;
  text-decoration: none;
  padding-left: 20px;
  line-height: 45px;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.container .facebook i,
.container .twitter i {
  padding-right: 12px;
  font-size: 20px;
}
.container .twitter a {
  background: linear-gradient(to right, #00acee 0%, #1abeff 100%);
  margin: 20px 0 15px 0;
}
.container .twitter a:hover {
  background: linear-gradient(to left, #00acee 0%, #1abeff 100%);
  margin: 20px 0 15px 0;
}
.container .facebook a {
  background: linear-gradient(to right, #3b5998 0%, #476bb8 100%);
  margin: 20px 0 50px 0;
}
.container .facebook a:hover {
  background: linear-gradient(to left, #3b5998 0%, #476bb8 100%);
  margin: 20px 0 50px 0;
}

.login-screen-wrapper {
  /* background: linear-gradient(
    109.6deg,
    rgb(0, 0, 0) 11.2%,
    rgb(16, 37, 60) 51.2%,
    rgb(36, 45, 57) 98.6%
  ); */
  background: rgb(19, 17, 28);
  background: linear-gradient(
    126deg,
    rgba(19, 17, 28, 1) 55%,
    rgba(83, 36, 50, 1) 100%
  );
}

.login-card {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  color: #ffffff;
  font-family: sans-serif;
  line-height: 1.5;
  padding: 1rem 2rem;
  z-index: 1000;
  background-color: rgba(68, 68, 68, 0.308);
  border-radius: 5px;
  /* backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.build-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  color: #ffffff;
  font-family: sans-serif;
  line-height: 1.5;
  max-width: 50%;
  padding: 1rem 2rem;
  z-index: 1000;
  font-size: 50px;
  width: 450px;
  line-height: 48px;
}

.roller {
  height: 4.125rem;
  line-height: 4rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 60px;
}

#spare-time {
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 1rem;
  margin-top: 0;
  color: #a8dadc;
}

.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
}

@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -4rem;
  }
  50% {
    top: -8rem;
  }
  72.5% {
    top: -12.25rem;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    text-align: center;
    text-transform: uppercase;
    color: #f1faee;
    font-size: 2.125rem;
  }

  .roller {
    height: 2.6rem;
    line-height: 2.125rem;
  }

  #spare-time {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }

  .roller #rolltext {
    animation: slide-mob 5s infinite;
  }

  @keyframes slide-mob {
    0% {
      top: 0;
    }
    25% {
      top: -2.125rem;
    }
    50% {
      top: -4.25rem;
    }
    72.5% {
      top: -6.375rem;
    }
  }
}




input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}