/* .custom-table {
  overflow-y: auto;
} */
.custom-table thead th {
  position: sticky;
  z-index: 3300;
  top: 0;
}

.custom-table table {
  font-size: 14px;
  border-collapse: collapse;
  table-layout: fixed;
  background: #fff;
}

/* .custom-table-heading{
  position: sticky;
  z-index: 1000;
  top: 0;
  border-bottom: 1px solid #000000;
} */

.custom-table th,
.custom-table td {
  padding: 8px 12px;
}
.custom-table th {
  background: #ffffff;
  color: #595959;
  font-weight: 500;
  font-size: 12px;
}

.custom-table .th-blue {
  background: #d8e0ff;
  color: #333333 !important;
}

.custom-table tr:nth-child(even) {
  background-color: #f8f7ff;
}

.search-bar-table {
  background: #ffffff !important;
  padding: 5px 14px !important;
  /* color: #fff !important; */
  border-radius: 5px !important;
  border: 1px solid #bebebe !important;
}

.table-head-holder {
  border-right: 1px solid rgb(179, 179, 179);
}

.toggle-btn {
  font-size: 13px !important;
  text-transform: none !important;
  padding: 5px 15px !important;
}

.col-sticky {
  left: 0;
  position: sticky;
  z-index: 3000 !important;
  background-color: rgb(255, 255, 255);
}
.col-sticky-head {
  left: 0;
  position: sticky;
  z-index: 3400 !important;
}

.custom-table .page-loader {
  background: rgba(255, 255, 255, 0.247);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);

  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3200 !important;
}

.table-header-comp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d8e0ff;
  padding-top: 10px;
  font-weight: 500;
  width: 100% !important;
}
