@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;700;800;900&family=RocknRoll+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

pre{
  font-family: "Manrope", sans-serif;
}

code {
  font-family: "Manrope", sans-serif;
}

.font-rocknroll{
  font-family: 'RocknRoll One', sans-serif;
  font-size:small;
}

.font-raleway{
  font-family: 'Raleway',sans-serif;
}

.font-inter{
  font-family: 'Inter',sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #efefef;
  border-radius: 0 !important;
  box-shadow: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
  border-radius: 0 !important;
  box-shadow: none !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(128, 128, 128);
  border-radius: 0 !important;
}

